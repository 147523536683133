import React from 'react';
import { Avatar, Card, CardContent, Typography, Grid, CardHeader } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment'; // Assuming it's an icon similar to your design
import PinIcon from '@mui/icons-material/Pin'; // Assuming it's an icon similar to your design
import { green } from '@mui/material/colors';

function DealOverviewCard({ totalDeals, divisions }) {
    return (
        <Card style={{height: '300px'}}>
        <CardHeader title="Deal Overview" />
        <CardContent>
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={6} container direction="column" justifyContent="center" alignItems="center">
              <Avatar sx={{ bgcolor: green[500], color: 'white', margin: '20px' }}>
                <ApartmentIcon />
              </Avatar>
              <Typography variant="h5">{totalDeals}</Typography>
              <Typography color="textSecondary">Total Deals</Typography>
            </Grid>
            <Grid item xs={6} container direction="column" justifyContent="center" alignItems="center">
              <Avatar sx={{ bgcolor: green[500], color: 'white', margin: '20px' }}>
                <PinIcon />
              </Avatar>
              <Typography variant="h5">{divisions}</Typography>
              <Typography color="textSecondary">Divisions</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
}

export default DealOverviewCard;
