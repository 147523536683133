import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import './App.css';
import 'leaflet/dist/leaflet.css';
import MappingPage from './Pages/mapping-page';
import MappingPage2 from './Pages/mapping-page-2';
import DashboardPage from './Pages/dashboard-page';
import LoginPage from './Pages/login-page';
import SideMenu from './SideMenu';
import oktaConfig from './config'; // Okta configuration file
import CustomSecureRoute from './CustomSecureRoute'; 
import CustomLoginCallback from './CustomLoginCallback'; // Custom LoginCallback component

const oktaAuth = new OktaAuth(oktaConfig);

function App(props) {

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(originalUri || window.location.origin);
  };

  return (

    <BrowserRouter>

      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <SideMenu client={props.client} />
        <div>
          <Routes>
            <Route path="/map" element={<CustomSecureRoute><MappingPage /></CustomSecureRoute>} />
            <Route path="/dashboard" element={<CustomSecureRoute><DashboardPage /></CustomSecureRoute>} />
            
            <Route path="/map2" element={<CustomSecureRoute><MappingPage2 /></CustomSecureRoute>} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/callback" element={<CustomLoginCallback />} />
            <Route path="/" element={<CustomSecureRoute><DashboardPage /></CustomSecureRoute>} />
          </Routes>
        </div>
      </Security>
    </BrowserRouter>
  );
}

export default App;
