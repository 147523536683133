import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Checkbox, 
  FormControlLabel, 
  Button, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  TextField 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CreateNewLead = ({onSaveLead}) => {
  const [selectedAPNs, setSelectedAPNs] = useState(["52-42-42-12-19-000-0011"]);
  const [expanded, setExpanded] = useState(false);

  const apnIDs = [
    "52-42-42-12-19-000-0011",
    "52-42-42-12-19-000-0012",
    "52-42-42-12-19-000-0013",
    "52-42-42-12-19-000-0014"
  ];

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedAPNs((prev) => 
      prev.includes(value) ? prev.filter((id) => id !== value) : [...prev, value]
    );
  };

  return (
    <Box p={3} width={400} bgcolor="white" boxShadow={3} borderRadius={2} overflow={'auto'}>
      <Typography variant="h6" gutterBottom>
        Create New Lead
      </Typography>
      <Typography variant="body2" gutterBottom>
        You are creating a new lead for the following APN ID(s):
      </Typography>
      <Typography variant="body1" gutterBottom>
        52-42-42-12-19-000-0011
      </Typography>
      <Typography variant="body2" gutterBottom>
        Need to select multiple APN IDs? Below are all APN IDs available in your current selection.
      </Typography>
      <Box>
        {apnIDs.map((id) => (
          <FormControlLabel
            key={id}
            control={
              <Checkbox 
                checked={selectedAPNs.includes(id)}
                onChange={handleChange}
                value={id}
              />
            }
            label={id}
          />
        ))}
      </Box>
      <Typography variant="h6" gutterBottom mt={2}>
        (Optional) Enter lead information
      </Typography>
      <Typography variant="body2" gutterBottom>
        Feel free to complete this section now or you can skip and come back to it in the generated lead.
      </Typography>
      <Accordion expanded={expanded === 'approvalForm'} onChange={() => setExpanded(expanded === 'approvalForm' ? false : 'approvalForm')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Approval Form</Typography>
          <Typography sx={{ color: 'text.secondary', marginLeft: 'auto' }}>10/17 fields</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField fullWidth margin="normal" label="Field 1" />
          <TextField fullWidth margin="normal" label="Field 2" />
          <TextField fullWidth margin="normal" label="Field 3" />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'purchaseContract'} onChange={() => setExpanded(expanded === 'purchaseContract' ? false : 'purchaseContract')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Purchase Contract & Checklist Summary</Typography>
          <Typography sx={{ color: 'text.secondary', marginLeft: 'auto' }}>4/7 fields</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField fullWidth margin="normal" label="Field 4" />
          <TextField fullWidth margin="normal" label="Field 5" />
        </AccordionDetails>
      </Accordion>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button variant="outlined">Cancel</Button>
        <Button variant="contained" color="primary" onClick={onSaveLead}>Create Lead</Button>
      </Box>
    </Box>
  );
};

export default CreateNewLead;
