// authStateUtils.js

export const setAuthState = (authState) => {
    localStorage.setItem('authState', JSON.stringify(authState));
  };
  
  export const getAuthState = () => {
    const authState = localStorage.getItem('authState');
    return authState ? JSON.parse(authState) : null;
  };
  
  export const removeAuthState = () => {
    localStorage.removeItem('authState');
  };
  