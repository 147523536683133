import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import DealOverviewCard from './deal-overview';

function DealList() {
  const deals = [
    { id: 1, division: "Bay Area", value: 90, deal: "Alley Tract" },
    { id: 2, division: "Bay Area", value: 36, deal: "Wheatland Tract" },
    { id: 3, division: "Bay Area", value: 24, deal: "Green Meadows" },
  ];


  const columns = [
    { field: 'deal', headerName: 'Deal', flex: 30 },
    { field: 'division', headerName: 'Division', flex: 30 },
    { field: 'value', headerName: 'Avg. Cost', flex: 30 }
  ];

  return (
    <Card style={{height: '300px'}}>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <DealOverviewCard totalDeals={500} divisions={30} />
          </Grid>

          <Grid item xs={6}>
            <DataGrid
              rows={deals}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default DealList;
