import React from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import { Card, CardContent } from '@mui/material';


const data = [
    { name: 'Pending', value: 150, fill: '#FF8042' },
    { name: 'Pipeline', value: 120, fill: '#FFBB28' },
    { name: 'Completed', value: 230, fill: '#00C49F' }
];

function DealIndexChart() {
    return (

        <Card style={{height: '300px'}}>
            <CardContent>
                <PieChart width={300} height={220}>
                    <Pie
                        data={data}
                        cx={140}
                        cy={90}
                        startAngle={0}
                        endAngle={360}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                    </Pie>
                    <Legend verticalAlign="top" height={36} />
                </PieChart>
            </CardContent>
            </Card>
    );
}

export default DealIndexChart;
