import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON, Marker, useMapEvents, useMap } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import SavePointModal from '../Modals/save-point-modal'
import CreateNewLead from '../Modals/create-new-lead'
import { Checkbox, FormControlLabel, Button, Select, Typography, MenuItem, CircularProgress } from '@mui/material';
import html2canvas from 'html2canvas'; // Add this import
import { HomeOutlined, List, NotificationsNone, Apps } from '@mui/icons-material';
import { useOktaAuth } from '@okta/okta-react'; // Import useOktaAuth

import NewPinDrop from '../Modals/new-pin-drop';
import ActiveDealPanel from '../Modals/active-deal';
import Pipeline from '../Modals/pipeline';

function MappingPage2() {
    const { oktaAuth, authState } = useOktaAuth(); // Destructure authState and oktaAuth
    const [address, setAddress] = useState("");
    
    const [customSearch, setCustomSearch] = useState("");
    const [position, setPosition] = useState([51.505, -0.09]);
    const [wetlandsString, setWetlandsString] = useState(null);
    const [speciesString] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [pointName, setPointName] = useState("");
    const [savedLocations, setSavedLocations] = useState([]);
    const [customSearchTypes, setCustomSearchTypes] = useState([]);
    const [zoomLevel] = useState(19);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState({});
    const [locationData, setLocationData] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchDistance, setSearchDistance] = useState(10000);  // Default search distance
    //const apiUrl = 'https://land-app-api-app-20240413100130.politedune-9e361e97.westus.azurecontainerapps.io';
    const apiUrl = 'https://land-app-api20240506235600.azurewebsites.net/';
   // const apiUrl = 'http://localhost:5147';    

const [activePanel, setActivePanel] = useState('NewPinDrop'); // State to track the active panel

   // const apiUrl = 'http://localhost:5147';

    const ChangeZoomControlPosition = () => {
        const map = useMap();
      
        React.useEffect(() => {
          // Remove existing zoom control
          map.zoomControl.remove();
      
          // Add zoom control at the bottom right position
          L.control.zoom({ position: 'bottomright' }).addTo(map);
        }, [map]);
      
        return null;
      };

    const layerStyles = [
        {
            fillColor: "green",
            color: "green",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        },
        {
            fillColor: "blue",
            color: "blue",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        },

        {
            fillColor: "red",
            color: "red",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        },

        {
            fillColor: "orange",
            color: "orange",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        },
        {
            fillColor: "brown",
            color: "brown",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        },

        {
            fillColor: "purple",
            color: "purple",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        }

    ]



    function getStyle(typeName) {
        let index = Object.keys(locationData).indexOf(typeName);
        return layerStyles[index % (layerStyles.length - 1)];
    }

    const customIcon = new L.Icon({
        iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Biohazard_symbol.svg/768px-Biohazard_symbol.svg.png?20211231015803',
        iconSize: [30, 30],
        iconAnchor: [0, 0], // Adjust as needed for proper positioning
        popupAnchor: [0, -50] // Adjust if the popup's position is not appropriate
    });

    const customSearchIcon = new L.Icon({
        iconUrl: 'https://landapp.blob.core.windows.net/imgs/thumbtack.png',
        iconSize: [40, 40],
        iconAnchor: [0, 0], // Adjust as needed for proper positioning
        popupAnchor: [0, -50] // Adjust if the popup's position is not appropriate
    });




    const pointerIcon = new L.Icon({
        iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Map_marker.svg/1334px-Map_marker.svg.png',
        iconSize: [30, 40],
        iconAnchor: [20, 10], // Adjust as needed for proper positioning
        popupAnchor: [0, -50] // Adjust if the popup's position is not appropriate
    });

    const highSeverityIcon = new L.Icon({
        iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Map_pin_icon.svg',
        iconSize: [30, 40],
        iconAnchor: [20, 10], // Adjust as needed for proper positioning
        popupAnchor: [0, -50] // Adjust if the popup's position is not appropriate
    });

    const lowSeverityIcon = new L.Icon({
        iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Map_pin_icon_green.svg/141px-Map_pin_icon_green.svg.png?20160227175221',
        iconSize: [30, 40],
        iconAnchor: [20, 10], // Adjust as needed for proper positioning
        popupAnchor: [0, -50] // Adjust if the popup's position is not appropriate
    });

    const handleAddressInput = (e) => {
        setAddress(e.target.value);
    };

    
    const handleCustomSearchInput = (e) => {
        setCustomSearch(e.target.value);
    };

    const handleDistanceInput = (e) => {
        setSearchDistance(e.target.value);
    };

    const handleChange = (event) => {
        const selectedId = event.target.value;
        const loc = savedLocations.find(l => l.id === selectedId);
        if (loc) {
            setPosition([loc.latitude, loc.longitude]); // Assuming your location data has Latitude and Longitude fields
            fetchWetlandsData(loc.latitude, loc.longitude);
        }
    }
    const handleViewDeal = (loc) => {
        if (loc) {
            setPosition([loc.latitude, loc.longitude]); // Assuming your location data has Latitude and Longitude fields
            categorySelected('Parcel','');
            fetchWetlandsData(loc.latitude, loc.longitude);
        } // Handle the selected location as needed
      };

      const handleCreateNewLead = (loc) => {
        setActivePanel('CreateNewLead')
      };

      const handleSaveLead = () =>{
        setModalOpen(true);
      }


    function MapEvents() {
        useMapEvents({
            dblclick(e) {
                const { lat, lng } = e.latlng;
                setPosition([lat, lng]);
                fetchWetlandsData(lat, lng);
                setActivePanel('NewPinDrop'); // Show the NewPinDrop panel
        
            },
            
        });
        return null; // No need to return anything from this component
    }

    const geocodeAddress = async () => {
        setLoading(true);
        const apiKey = 'b88472c4606e4f4dafb048075349230d';
        const requestUrl = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(address)}&key=${apiKey}`;

        try {
            const response = await axios.get(requestUrl);
            if (response.data.results.length > 0) {
                const { lat, lng } = response.data.results[0].geometry;
                setPosition([lat, lng]);

                await fetchWetlandsData(lat, lng);
            } else {
                alert('No results found. Please try a different address.');
            }
        } catch (error) {
            console.error('Failed to fetch geocoding data:', error);
            alert('Error fetching geocoding data. Please check console for details.');
        }
        setLoading(false);
    };

    const fetchWetlandsData = async (lat, lng) => {
        setLoading(true);
        setLocationData({});
        try {
            const fetches = [];

            setWetlandsString('Fetching data...');
            for (var i in selectedCategories) {
                if (selectedCategories[i]) {
                    fetches.push(fetchLocationData(lat, lng, i, {}));
                }
            }
            await Promise.all(fetches)
            setLoading(false);
            setWetlandsString('Found records.');
       
        } catch (error) {
            console.error('Failed to fetch location data:', error);
            alert('Error fetching map data. Please check console for details.');
        }
    };


    const fetchCategoryData = async () => {
        if (categories.length === 0 && !categoriesLoading) {
            setCategoriesLoading(true);
            try {
                setWetlandsString('Fetching location categories...');

                const response = await axios.get(apiUrl + `/api/Location/GetCategories`);
                setCategories(response.data);
                setWetlandsString('');


            } catch (error) {
                console.error('Failed to fetch category data:', error);
                alert('Error fetching map data. Please check console for details.');
            }
            setCategoriesLoading(false);
        }
    };

    const categorySelected = async (text, value) => {
        let selectedCats = selectedCategories;
        selectedCats[text] = !selectedCats[text];
        setSelectedCategories(selectedCats);
        setWetlandsString('Fetching locations...');
               
        await fetchLocationData(position[0], position[1], text);
        setWetlandsString('');
        
    }

    
    const customSearchStart = async (text, value) => {
        setWetlandsString('Fetching locations...');
               
        await fetchCustomLocationData(position[0], position[1], customSearch);
        setWetlandsString('');
        
    }



    const fetchLocationData = async (lat, lng, category, locDataOverride) => {
        setLoading(true);
        if (selectedCategories[category]) {
            
            let locData = locationData;
            if(locDataOverride){
                locData = locDataOverride;
            }
            try {
                if (!locData[category]) {
                    const response = await axios.get(apiUrl + `/api/Location/GetLocations?category=${category}&lat=${lat}&lon=${lng}&distance=${searchDistance}`);
                    locData[category] = JSON.parse(response.data.data);
                    setLocationData(locData);
                    setWetlandsString('');
                } else {
                    await Promise.resolve();
                }


            } catch (error) {
                console.error('Failed to fetch category data:', error);
                alert('Error fetching map data. Please check console for details.');
            }
        } else {
            //await axios.get(apiUrl + `/api/Location/GetLocations?category=poop&lat=${lat}&lon=${lng}&distance=${searchDistance}`);
            await Promise.resolve();

        }
        setLoading(false);

    };

    

    const fetchCustomLocationData = async (lat, lng, customLocation) => {
        setLoading(true);
        
        if(categories.indexOf(customLocation) === -1){
            let newCats = categories;
            newCats.push(customLocation);
            setCategories(newCats);
            let selectedCats = selectedCategories;
            selectedCats[customLocation] = true;

            let custSearches = customSearchTypes;
            custSearches.push(customLocation);
            setCustomSearchTypes(custSearches);
        }
        if (selectedCategories[customLocation]) {
            
            let locData = locationData;
            
            try {
                if (!locData[customLocation]) {
                    const response = await axios.get(apiUrl + `/api/Location/GetCustomLocations?query=${customLocation}&lat=${lat}&lon=${lng}`);
                    locData[customLocation] = JSON.parse(response.data.data);
                    setLocationData(locData);
                    setWetlandsString('');
                } else {
                    await Promise.resolve();
                }


            } catch (error) {
                console.error('Failed to fetch category data:', error);
                alert('Error fetching map data. Please check console for details.');
            }
        } else {
            //await axios.get(apiUrl + `/api/Location/GetLocations?category=poop&lat=${lat}&lon=${lng}&distance=${searchDistance}`);
            await Promise.resolve();

        }
        setLoading(false);

    };



    const fetchSavedLocations = async (lat, lng, category) => {
        if (savedLocations.length === 0) {
            setLoading(true);
            try {
                setWetlandsString('Fetching locations...');

                const userInfo = await oktaAuth.getUser();
                const response = await axios.get(apiUrl + `/api/SavedLocations?uid=` + userInfo.sub);
                setSavedLocations(response.data);
                setWetlandsString('');


            } catch (error) {
                console.error('Failed to fetch category data:', error);
                alert('Error fetching map data. Please check console for details.');
            }
            setLoading(false);
        }
    };

    const handleSavePoint = async (name) => {
        if (authState && authState.isAuthenticated) {
            const userInfo = await oktaAuth.getUser();
            const payload = {
                Name: name,
                Latitude: position[0],
                Longitude: position[1],
                Id: '',
                CreatedBy: userInfo.sub // Add the userId to the payload
            };

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            try {
                const response = await axios.post(apiUrl + '/api/SavedLocations', payload, config);
                console.log('Point saved:', response.data);
            } catch (error) {
                console.error('Error saving point:', error);
                alert('Failed to save the point. Please check the console for details.');
            }
        }
    };

    const onEachFeature = (feature, layer) => {
        if (feature.properties) {
            let popupContent = '<h3>Details:</h3><ul>'
            for (var i in feature.properties) {
                if(i.toLowerCase() !== 'icon'){
                    popupContent += '<li>' + i + ':' + feature.properties[i] + '</li>';
                }
            }
            popupContent += '</ul>';

            layer.bindPopup(popupContent);
        }
    };
    useEffect(() => {
        fetchCategoryData();
        fetchSavedLocations();
    });

    

    // Function to capture the map as an image
    const captureMap = async () => {
        const mapElement = document.querySelector('.leaflet-container'); // Adjust selector if necessary
        if (!mapElement) return;

        const canvas = await html2canvas(mapElement);
        const dataUrl = canvas.toDataURL('image/png');

        // Send the image to the server
        const response = await axios.post(apiUrl + '/api/PDF/UploadMapImage', { image: dataUrl }, {
            responseType: 'blob' // Important for handling file download
        });

        // Create a link element, use it to create a URL for the blob, and trigger a download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'map.pdf'); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };



    return (
        <div className="menu-shim-2 App">
         
         <div className='main-button-bar'>
         <Button color="inherit" startIcon={<HomeOutlined />} onClick={() => setActivePanel('NewPinDrop')}>
                    Home
                </Button>
                <Button color="inherit" startIcon={<Apps />} onClick={() => setActivePanel('Pipeline')}>
                    Pipeline
                </Button>
                <Button color="inherit" startIcon={<NotificationsNone />}>
                    Activity
                </Button>
                <Button color="inherit" startIcon={<List />}>
                    Task
                </Button>  
        </div>

        {activePanel === 'NewPinDrop' && (
                <div className='main-pin-drop'>
                    <NewPinDrop latitude={position[0]} longitude={position[1]} onCreateNewLead={handleCreateNewLead}/>
                </div>
            )}
            {activePanel === 'ActiveDealPanel' && (
                <div className='active-deal-panel'>
                    <ActiveDealPanel />
                </div>
            )}
            {activePanel === 'Pipeline' && (
                <div className='pipeline-panel'>
                    <Pipeline locations={savedLocations} onViewDeal={handleViewDeal} />
                </div>
            )}
            {activePanel === 'CreateNewLead' && (
                <div className='create-new-lead'>
                    <CreateNewLead onSaveLead={handleSaveLead}/>
                </div>
            )}
            <div className='main-search-bar'>
            <Select
                    value=""
                    variant='standard'
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="">
                        All
                    </MenuItem>
                    
                </Select>
            <input
                    type="text"
                    value={address}
                    disabled={loading}
                    onChange={handleAddressInput}
                    placeholder="Enter an address"
                    onKeyDown={(e) => e.key === 'Enter' && geocodeAddress()}
                /> 
            </div>


            <div className="App-header-2">
                
                <input
                    hidden={true}
                    type="number"
                    value={searchDistance}
                    onChange={handleDistanceInput}
                    disabled={loading}
                    placeholder="Search Distance (meters)"
                />
                <Button onClick={geocodeAddress} disabled={loading}>
                    {loading ? 'Loading...' : 'Locate'}
                </Button>

                {categories.map((text, index) => (
                    <div>
                        <FormControlLabel
                            label={text}
                            control={<Checkbox type="checkbox" disabled={loading} checked={selectedCategories[text]} onChange={() => categorySelected(text, !selectedCategories[text])} />
                            }
                        />
                    </div>
                ))}

                <input
                    type="text"
                    value={customSearch}
                    disabled={loading}
                    onChange={handleCustomSearchInput}
                    placeholder="Enter a location type"
                    onKeyDown={(e) => e.key === 'Enter'}
                />

                <Button variant="contained" color="primary" onClick={() => customSearchStart() } 
                    disabled={loading}>
                    Search Custom Location
                </Button>
                <hr/>
                <br/>
                <SavePointModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onSave={handleSavePoint}
                    pointName={pointName}
                    setPointName={setPointName}
                />
                <Typography variant="h6">Saved Locations</Typography>
                <Select
                    value=""
                    
                    disabled={loading}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="" disabled>
                        Select Location
                    </MenuItem>
                    {savedLocations.map((loc) => (
                        <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem> // Ensure loc has a Name property
                    ))}
                </Select>
                <br />
                <Button variant="contained" color="primary" onClick={() => setModalOpen(true)} 
                    disabled={loading}>
                    Save Location
                </Button>

                {wetlandsString && <h4>{wetlandsString}</h4>}

                {speciesString && <h4>{speciesString}</h4>}

                <Button variant="contained" color="secondary" onClick={captureMap}>
                    Capture Map
                </Button>

            </div>
            <div className="map-container" style={{ position: 'relative' }}>
            {loading && (
                    <div style={{ zIndex: 4000, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255, 255, 255, 0.4)' }}>
                        <CircularProgress />
                    </div>
                )}
                <MapContainer center={position} zoom={zoomLevel} style={{ height: '100%', width: '100%' }} key={Date.now()}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />

                    <MapEvents />
                    <Marker position={position} icon={pointerIcon} />
                    {savedLocations.map((loc) => {
    const hasHighSeverity = loc.insights.some(insight => insight.severity === "High");
    const icon = hasHighSeverity ? highSeverityIcon : lowSeverityIcon;

    return (
        <Marker
            key={loc.id}
            position={[loc.latitude, loc.longitude]}
            icon={icon}
        />
    );
})}
                    {Object.keys(locationData).map((location) => (
                        selectedCategories[location] && <GeoJSON data={locationData[location]} style={getStyle(location)} onEachFeature={onEachFeature}
                            pointToLayer={(feature, latlng) => {
                                // Check if the feature is a point
                                if (feature.geometry.type === 'Point' ) {
                                    if(customSearchTypes.indexOf(location) === -1){
                                        return L.marker(latlng, { icon: customIcon });
                                    }else{
                                        // TODO: I want to create an icon here that has the url that is stored in feature.properties.Icon
                                        const iconUrl = feature.properties.Icon || customSearchIcon.options.iconUrl;
                                        const gIcon = new L.Icon({
                                            iconUrl: iconUrl,
                                            iconSize: [40, 40],
                                            iconAnchor: [0, 0],
                                            popupAnchor: [20, 0]
                                        });
                                        return L.marker(latlng, { icon: gIcon });
                                    }
                                }
                                // Optionally handle other geometry types differently
                                return L.geoJSON(feature);
                            }}
                        />

                    ))}

      <ChangeZoomControlPosition />
                </MapContainer>
            </div>
        </div>
    );
}

export default MappingPage2;
