import React from 'react';
import { 
  Box, 
  Typography,
  Card, 
  CardContent, 
  Chip, 
  Button, 
  IconButton, 
  Menu, 
  MenuItem 
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';

const DealCard = ({ location, onViewDeal }) => {
  const hasHighSeverityInsight = location.insights.some(insight => insight.severity === 'High');
  const status = hasHighSeverityInsight ? 'High Risk' : 'Low Risk';
  const statusColor = hasHighSeverityInsight ? 'error' : 'success';

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h6">{location.name}</Typography>
          <Chip label={status} color={statusColor} />
        </Box>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Latitude: {location.latitude.toFixed(4)}, Longitude: {location.longitude.toFixed(4)}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {location.status || "Not Under LOI"} • {location.rank || "Rank 123"}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {location.city || location.state ? `${location.city}, ${location.state}` : "Location Unknown"}
        </Typography>
        {hasHighSeverityInsight && (
          <Box bgcolor="error.lighter" p={2} borderRadius={1} mb={2}>
            <Typography variant="body2" color="error" gutterBottom>
              {location.insights[0].type}: {location.insights[0].severity}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Distance: {location.insights[0].distance.toFixed(2)} meters
            </Typography>
          </Box>
        )}
        <Typography variant="body2" gutterBottom>
          APN ID(s):
        </Typography>
        <Typography variant="body2" gutterBottom>
          {location.apnIds ? location.apnIds.join(', ') : "42-42-42-12-19-000-0011, 42-42-42-12-19-000-0012"}
        </Typography>
        <Typography variant="body2" gutterBottom>
          Next Key Date: <strong>{location.nextKeyDate || "IP Expiration (03/31/23)"}</strong>
        </Typography>
        <Typography variant="body2" gutterBottom>
          Likelihood: {location.likelihood || "60%"}
        </Typography>
        <Typography variant="body2" gutterBottom>
          Homesite Total: {location.homesiteTotal || 143}
        </Typography>
        <Typography variant="body2" gutterBottom>
          Price Per Unit: {location.pricePerUnit || "$150.00"}
        </Typography>
        <Button variant="contained" color="primary" onClick={() => onViewDeal(location)}>
          View Deal
        </Button>
      </CardContent>
    </Card>
  );
};

const Pipeline = ({ locations, onViewDeal }) => {
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const [sortAnchorEl, setSortAnchorEl] = React.useState(null);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Pipeline
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Total Locations: {locations.length}
      </Typography>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <IconButton onClick={handleFilterClick}>
          <FilterListIcon />
        </IconButton>
        <Menu
          anchorEl={filterAnchorEl}
          open={Boolean(filterAnchorEl)}
          onClose={handleFilterClose}
        >
          <MenuItem onClick={handleFilterClose}>Filter Option 1</MenuItem>
          <MenuItem onClick={handleFilterClose}>Filter Option 2</MenuItem>
        </Menu>
        <IconButton onClick={handleSortClick}>
          <SortIcon />
        </IconButton>
        <Menu
          anchorEl={sortAnchorEl}
          open={Boolean(sortAnchorEl)}
          onClose={handleSortClose}
        >
          <MenuItem onClick={handleSortClose}>Sort Option 1</MenuItem>
          <MenuItem onClick={handleSortClose}>Sort Option 2</MenuItem>
        </Menu>
      </Box>
      {locations.map((location) => (
        <DealCard key={location.id} location={location} onViewDeal={onViewDeal} />
      ))}
    </Box>
  );
};

export default Pipeline;
