import { Button, Modal, Box, TextField } from '@mui/material';


function SavePointModal({ open, onClose, onSave, pointName, setPointName }) {


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleSave = () => {
        onSave(pointName);
        setPointName(""); // Reset the input field after saving
        onClose(); // Close the modal
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="save-point-modal-title"
            aria-describedby="save-point-modal-description"
        >
            <Box sx={style}>
                <h2 id="save-point-modal-title">Save New Lead</h2>
                <TextField
                    fullWidth
                    label="Lead Name"
                    value={pointName}
                    onChange={(e) => setPointName(e.target.value)}
                    margin="normal"
                />
                <Button onClick={handleSave} variant="contained" color="primary">
                    Save
                </Button>
            </Box>
        </Modal>
    );
}

export default SavePointModal;