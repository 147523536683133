import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { setAuthState } from './AuthStateUtils';

const CustomLoginCallback = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Handle the callback with Okta
    const handleLoginCallback = async () => {
      try {
        await oktaAuth.handleLoginRedirect();
      } catch (error) {
        console.error(error);
      }
    };

    handleLoginCallback();
  }, [oktaAuth]);

  useEffect(() => {
    // Navigate to originalUri or home page after successful authentication
    if (authState && authState.isAuthenticated) {
        
      setAuthState(authState); // Store authState in localStorage
      const originalUri = oktaAuth.getOriginalUri();
      navigate(originalUri || '/', { replace: true });
      oktaAuth.removeOriginalUri();
    }
  }, [authState, navigate, oktaAuth]);

  return <div>Loading...</div>;
};

export default CustomLoginCallback;
