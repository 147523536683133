import React from 'react';
import { Box, Button, Typography, Card, CardContent } from '@mui/material';

const NewPinDrop = ({ latitude, longitude, onCreateNewLead }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          New Pin Drop
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          You've dropped a new pin on: {latitude}, {longitude}
        </Typography>
        <Typography variant="body2">
          This seems to be an available and unclaimed coordinate.
        </Typography>
        <Box mt={2}>
          <Typography variant="body2" color="text.secondary">
            Create a new lead for this coordinate?
          </Typography>
          <Button
            variant="contained"
            color="success"
            sx={{ mt: 1 }}
            onClick={onCreateNewLead}
          >
            Create New Lead
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NewPinDrop;
