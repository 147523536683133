import React from 'react';
import DealHealthCard from '../Graph Widgets/deal-health';
import DealList from '../Graph Widgets/deal-list';
import { Typography, Grid, Box, MenuItem, Container, Select } from '@mui/material';
import PendingReasons from '../Graph Widgets/pending-reasons';
import OpenDeals from '../Graph Widgets/open-deals';
import TargetVsActualsChart from '../Graph Widgets/target-vs-actual';

import { Card, CardContent, CardHeader } from '@mui/material';


function DashboardPage() {

    function handleChange() {

    }

    return (
        <div className="menu-shim">
            <Grid md={12} container spacing={1} rowSpacing={2}>

                <Grid item xs={5} md={5}>
                    <DealList />

                </Grid>

                <Grid item xs={4} md={4}>

                    <DealHealthCard onTrack={250} needsAttention={200} offTrack={50} />
                </Grid>
            </Grid>
            <br></br>
            <Grid md={12} container spacing={1}>
                <Grid item xs={7} md={7}>
                    <PendingReasons ></PendingReasons>
                </Grid>
                <Grid item xs={5} md={5}>
                    <OpenDeals></OpenDeals>
                </Grid>
            </Grid>
            <Grid md={12} container spacing={1}>
                <Grid item xs={4} md={4}>

                </Grid>
                <Grid item xs={5} md={5}>
                </Grid>
            </Grid>
            <br/>
            <Grid padding={'10px'}>
                <Grid item md={12}>

                    <Card>
                        <CardHeader title="Target vs. Actual">
                        </CardHeader>
                        <CardContent>
                            <Container>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6">Target Vs Actuals ($/Sq.Ft)</Typography>
                                    <Select
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="Alley Tract">Alley Tract</MenuItem>
                                        <MenuItem value="Green Meadows">Green Meadows</MenuItem>
                                        <MenuItem value="Wheatland tract">Wheatland tract</MenuItem>
                                    </Select>
                                </Box>
                                <TargetVsActualsChart />
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </div>
    );
}

export default DashboardPage;
