import React from 'react';
import { Card, CardContent, Typography, Grid, CardHeader } from '@mui/material';

function DealHealthCard({ onTrack, needsAttention, offTrack }) {
  return (
    <Card style={{height: '300px'}}>
        <CardHeader title="Deal Health">
        </CardHeader>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography padding={'15px'} variant="h3" color="success.main">{onTrack}</Typography>
            <Typography  padding={'20px'} color="textSecondary">On Track</Typography>
          </Grid>
          <Grid item xs>
            <Typography padding={'15px'} variant="h3" color="warning.main">{needsAttention}</Typography>
            <Typography padding={'20px'} color="textSecondary">Needs Attention</Typography>
          </Grid>
          <Grid item xs>
            <Typography padding={'15px'} variant="h3" color="error.main">{offTrack}</Typography>
            <Typography padding={'20px'} color="textSecondary">Off Track</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default DealHealthCard;
