const oktaConfig = {
    clientId: '0oahail96psixArLG5d7',
    issuer: 'https://dev-61147351.okta.com/oauth2/default',
    redirectUri: window.location.origin + '/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    tokenManager: {
        storage: 'localStorage' // or 'sessionStorage', 'cookie'
      }
  };
  
  export default oktaConfig;