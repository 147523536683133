import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import DealIndexGauge from './deal-index'
import { DataGrid } from '@mui/x-data-grid';

function PendingReasons() {
    const reasons = [
        { id: 1, reason: "Regional council approval Pending", count: 90 },
        { id: 2, reason: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", count: 36 },
        { id: 3, reason: "Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet.", count: 24 }
    ];


    const columns = [
        { field: 'reason', headerName: 'Pending Reasons', flex: 70 },
        { field: 'count', headerName: 'No. of Deals', flex: 30 },
    ];

    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs={5}>

                        <DealIndexGauge totalDeals={500} divisions={30} />
                    </Grid>
                    <Grid item xs={7}>

                    <DataGrid
                            rows={reasons}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                        />
                        
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default PendingReasons;
