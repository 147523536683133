import React from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Divider, 
  Link, 
  Chip 
} from '@mui/material';

const ActiveDealPanel = ({ hasCriticalErrors }) => {
    hasCriticalErrors = true;
  return (
    <Box p={3} width={400} bgcolor="white" boxShadow={3} borderRadius={2}>
      <Button variant="text" color="primary" sx={{ mb: 2 }}>
        &lt; Back to Pipeline
      </Button>
      <Typography variant="h6" gutterBottom>
        Bridge Stone, D00008888
      </Typography>
      {hasCriticalErrors ? (
        <Chip label="Critical" color="error" sx={{ mb: 2 }} />
      ) : (
        <Chip label="Qualified" color="primary" sx={{ mb: 2 }} />
      )}
      <Typography variant="body2" gutterBottom>
        Not Under LOI • Rank 123
      </Typography>
      <Typography variant="body2" gutterBottom>
        Apopka, FL - 33.71°N, 117.12°W
      </Typography>
      {hasCriticalErrors && (
        <Box bgcolor="error.lighter" p={2} borderRadius={1} mb={2}>
          <Typography variant="body2" color="error" gutterBottom>
            Deal has critical risks that require immediate action
          </Typography>
          <Link href="#" color="error" underline="hover">
            Review deal
          </Link>
        </Box>
      )}
      <Typography variant="body2" gutterBottom>
        APN ID(s):
      </Typography>
      <Typography variant="body2" gutterBottom>
        42-42-42-12-19-000-0011<br />
        42-42-42-12-19-000-0012
      </Typography>
      <Typography variant="body2" gutterBottom>
        Next Key Date: <strong>IP Expiration (03/31/23)</strong>
      </Typography>
      <Typography variant="body2" gutterBottom>
        Likelihood: 60%
      </Typography>
      <Typography variant="body2" gutterBottom>
        Homesite Total: 143
      </Typography>
      <Typography variant="body2" gutterBottom>
        Price Per Unit: $150.00
      </Typography>
      <Box my={2}>
        <img
          src="your_image_url" // replace with your image URL
          alt="Deal"
          style={{ width: '100%', borderRadius: '8px' }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        {!hasCriticalErrors && (
          <Button variant="outlined" color="primary">Create Deal</Button>
        )}
        <Button variant="contained" color="primary">Open Lead</Button>
      </Box>
      <Typography variant="body2" gutterBottom>
        Not seeing latest updates? <Link href="#">Refresh data</Link>
      </Typography>
      <Typography variant="body2" gutterBottom>
        No longer pursuing this? <Link href="#">Archive</Link>
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6" gutterBottom>
        Key Dates
      </Typography>
      <Typography variant="body2" gutterBottom>
        Showing 3 of 20 dates
      </Typography>
      <Box>
        <Typography variant="body2">
          <strong>IP Expiration</strong><br />
          Target: 03/31/2023
        </Typography>
        <Typography variant="body2">
          <strong>LOI Offer Due</strong>
        </Typography>
      </Box>
    </Box>
  );
};

export default ActiveDealPanel;
