import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import SavePointModal from '../Modals/save-point-modal'
import { Checkbox, FormControlLabel, Button, Select, Typography, MenuItem, CircularProgress } from '@mui/material';
import html2canvas from 'html2canvas'; // Add this import


function MappingPage() {
    const [address, setAddress] = useState("");
    const [customSearch, setCustomSearch] = useState("");
    const [position, setPosition] = useState([51.505, -0.09]);
    const [wetlandsString, setWetlandsString] = useState(null);
    const [speciesString] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [pointName, setPointName] = useState("");
    const [savedLocations, setSavedLocations] = useState([]);
    const [customSearchTypes, setCustomSearchTypes] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(19);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState({});
    const [locationData, setLocationData] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchDistance, setSearchDistance] = useState(10000);  // Default search distance
    //const apiUrl = 'https://land-app-api-app-20240413100130.politedune-9e361e97.westus.azurecontainerapps.io';
    const apiUrl = 'https://land-app-api20240506235600.azurewebsites.net/';

    //const apiUrl = 'http://localhost:5147';


    const layerStyles = [
        {
            fillColor: "green",
            color: "green",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        },
        {
            fillColor: "blue",
            color: "blue",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        },

        {
            fillColor: "red",
            color: "red",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        },

        {
            fillColor: "orange",
            color: "orange",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        },
        {
            fillColor: "brown",
            color: "brown",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        },

        {
            fillColor: "purple",
            color: "purple",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.05
        }

    ]



    function getStyle(typeName) {
        let index = Object.keys(locationData).indexOf(typeName);
        return layerStyles[index % (layerStyles.length - 1)];
    }

    const customIcon = new L.Icon({
        iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Biohazard_symbol.svg/768px-Biohazard_symbol.svg.png?20211231015803',
        iconSize: [30, 30],
        iconAnchor: [0, 0], // Adjust as needed for proper positioning
        popupAnchor: [0, -50] // Adjust if the popup's position is not appropriate
    });

    const customSearchIcon = new L.Icon({
        iconUrl: 'https://landapp.blob.core.windows.net/imgs/thumbtack.png',
        iconSize: [40, 40],
        iconAnchor: [0, 0], // Adjust as needed for proper positioning
        popupAnchor: [0, -50] // Adjust if the popup's position is not appropriate
    });




    const pointerIcon = new L.Icon({
        iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Map_pin_icon.svg',
        iconSize: [30, 40],
        iconAnchor: [20, 10], // Adjust as needed for proper positioning
        popupAnchor: [0, -50] // Adjust if the popup's position is not appropriate
    });

    const handleAddressInput = (e) => {
        setAddress(e.target.value);
    };

    
    const handleCustomSearchInput = (e) => {
        setCustomSearch(e.target.value);
    };

    const handleDistanceInput = (e) => {
        setSearchDistance(e.target.value);
    };

    const handleChange = (event) => {
        const selectedId = event.target.value;
        const loc = savedLocations.find(l => l.id === selectedId);
        if (loc) {
            setPosition([loc.latitude, loc.longitude]); // Assuming your location data has Latitude and Longitude fields
            fetchWetlandsData(loc.latitude, loc.longitude);
        }
    }

    function MapEvents() {
        useMapEvents({
            dblclick(e) {
                const { lat, lng } = e.latlng;
                setPosition([lat, lng]);
                fetchWetlandsData(lat, lng);
            },
            zoomend(e) {
                setZoomLevel(e.target.getZoom());
            }
        });
        return null; // No need to return anything from this component
    }

    const geocodeAddress = async () => {
        setLoading(true);
        const apiKey = 'b88472c4606e4f4dafb048075349230d';
        const requestUrl = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(address)}&key=${apiKey}`;

        try {
            const response = await axios.get(requestUrl);
            if (response.data.results.length > 0) {
                const { lat, lng } = response.data.results[0].geometry;
                setPosition([lat, lng]);

                await fetchWetlandsData(lat, lng);
            } else {
                alert('No results found. Please try a different address.');
            }
        } catch (error) {
            console.error('Failed to fetch geocoding data:', error);
            alert('Error fetching geocoding data. Please check console for details.');
        }
        setLoading(false);
    };

    const fetchWetlandsData = async (lat, lng) => {
        setLoading(true);
        setLocationData({});
        try {
            const fetches = [];

            setWetlandsString('Fetching data...');
            for (var i in selectedCategories) {
                if (selectedCategories[i]) {
                    fetches.push(fetchLocationData(lat, lng, i, {}));
                }
            }
            await Promise.all(fetches)
            setLoading(false);
            setWetlandsString('Found records.');
       
        } catch (error) {
            console.error('Failed to fetch location data:', error);
            alert('Error fetching map data. Please check console for details.');
        }
    };


    const fetchCategoryData = async () => {
        if (categories.length === 0 && !categoriesLoading) {
            setCategoriesLoading(true);
            try {
                setWetlandsString('Fetching location categories...');

                const response = await axios.get(apiUrl + `/api/Location/GetCategories`);
                setCategories(response.data);
                setWetlandsString('');


            } catch (error) {
                console.error('Failed to fetch category data:', error);
                alert('Error fetching map data. Please check console for details.');
            }
            setCategoriesLoading(false);
        }
    };

    const categorySelected = async (text, value) => {
        let selectedCats = selectedCategories;
        selectedCats[text] = !selectedCats[text];
        setSelectedCategories(selectedCats);
        setWetlandsString('Fetching locations...');
               
        await fetchLocationData(position[0], position[1], text);
        setWetlandsString('');
        
    }

    
    const customSearchStart = async (text, value) => {
        setWetlandsString('Fetching locations...');
               
        await fetchCustomLocationData(position[0], position[1], customSearch);
        setWetlandsString('');
        
    }



    const fetchLocationData = async (lat, lng, category, locDataOverride) => {
        setLoading(true);
        if (selectedCategories[category]) {
            
            let locData = locationData;
            if(locDataOverride){
                locData = locDataOverride;
            }
            try {
                if (!locData[category]) {
                    const response = await axios.get(apiUrl + `/api/Location/GetLocations?category=${category}&lat=${lat}&lon=${lng}&distance=${searchDistance}`);
                    locData[category] = JSON.parse(response.data.data);
                    setLocationData(locData);
                    setWetlandsString('');
                } else {
                    await Promise.resolve();
                }


            } catch (error) {
                console.error('Failed to fetch category data:', error);
                alert('Error fetching map data. Please check console for details.');
            }
        } else {
            //await axios.get(apiUrl + `/api/Location/GetLocations?category=poop&lat=${lat}&lon=${lng}&distance=${searchDistance}`);
            await Promise.resolve();

        }
        setLoading(false);

    };

    

    const fetchCustomLocationData = async (lat, lng, customLocation) => {
        setLoading(true);
        
        if(categories.indexOf(customLocation) === -1){
            let newCats = categories;
            newCats.push(customLocation);
            setCategories(newCats);
            let selectedCats = selectedCategories;
            selectedCats[customLocation] = true;

            let custSearches = customSearchTypes;
            custSearches.push(customLocation);
            setCustomSearchTypes(custSearches);
        }
        if (selectedCategories[customLocation]) {
            
            let locData = locationData;
            
            try {
                if (!locData[customLocation]) {
                    const response = await axios.get(apiUrl + `/api/Location/GetCustomLocations?query=${customLocation}&lat=${lat}&lon=${lng}`);
                    locData[customLocation] = JSON.parse(response.data.data);
                    setLocationData(locData);
                    setWetlandsString('');
                } else {
                    await Promise.resolve();
                }


            } catch (error) {
                console.error('Failed to fetch category data:', error);
                alert('Error fetching map data. Please check console for details.');
            }
        } else {
            //await axios.get(apiUrl + `/api/Location/GetLocations?category=poop&lat=${lat}&lon=${lng}&distance=${searchDistance}`);
            await Promise.resolve();

        }
        setLoading(false);

    };



    const fetchSavedLocations = async (lat, lng, category) => {
        if (savedLocations.length === 0) {
            setLoading(true);
            try {
                setWetlandsString('Fetching locations...');

                const response = await axios.get(apiUrl + `/api/SavedLocations`);
                setSavedLocations(response.data);
                setWetlandsString('');


            } catch (error) {
                console.error('Failed to fetch category data:', error);
                alert('Error fetching map data. Please check console for details.');
            }
            setLoading(false);
        }
    };

    const handleSavePoint = async (name) => {
        const payload = {
            Name: name,
            Latitude: position[0],
            Longitude: position[1],
            Id: ''
        };

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            const response = await axios.post(apiUrl + '/api/SavedLocations', payload, config);
            console.log('Point saved:', response.data);
            // Optionally, add any follow-up actions here, like updating UI or state to show confirmation
        } catch (error) {
            console.error('Error saving point:', error);
            alert('Failed to save the point. Please check the console for details.');
        }
    };

    /* const fetchSavedLocations = async (lat, lng) => {
       setLoading(true);
       try {
         const response = await axios.get(apiUrl + `/api/SavedLocations/`);
        // setSavedLocations(response.data);
   
       } catch (error) {
         console.error('Failed to fetch location data:', error);
         alert('Error fetching map data. Please check console for details.');
       }
       setLoading(false);
     };*/


    const onEachFeature = (feature, layer) => {
        if (feature.properties) {
            let popupContent = '<h3>Details:</h3><ul>'
            for (var i in feature.properties) {
                if(i.toLowerCase() !== 'icon'){
                    popupContent += '<li>' + i + ':' + feature.properties[i] + '</li>';
                }
            }
            popupContent += '</ul>';

            layer.bindPopup(popupContent);
        }
    };
    useEffect(() => {
        fetchCategoryData();
        fetchSavedLocations();
    });

    // Function to capture the map as an image
    const captureMap = async () => {
        const mapElement = document.querySelector('.leaflet-container'); // Adjust selector if necessary
        if (!mapElement) return;

        const canvas = await html2canvas(mapElement);
        const dataUrl = canvas.toDataURL('image/png');

        // Send the image to the server
        const response = await axios.post(apiUrl + '/api/PDF/UploadMapImage', { image: dataUrl }, {
            responseType: 'blob' // Important for handling file download
        });

        // Create a link element, use it to create a URL for the blob, and trigger a download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'map.pdf'); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };



    return (
        <div className="menu-shim App">

            <div className="App-header">
                <input
                    type="text"
                    value={address}
                    disabled={loading}
                    onChange={handleAddressInput}
                    placeholder="Enter an address"
                    onKeyDown={(e) => e.key === 'Enter' && geocodeAddress()}
                />
                <input
                    hidden={true}
                    type="number"
                    value={searchDistance}
                    onChange={handleDistanceInput}
                    disabled={loading}
                    placeholder="Search Distance (meters)"
                />
                <Button onClick={geocodeAddress} disabled={loading}>
                    {loading ? 'Loading...' : 'Locate'}
                </Button>

                {categories.map((text, index) => (
                    <div>
                        <FormControlLabel
                            label={text}
                            control={<Checkbox type="checkbox" disabled={loading} checked={selectedCategories[text]} onChange={() => categorySelected(text, !selectedCategories[text])} />
                            }
                        />
                    </div>
                ))}

                <input
                    type="text"
                    value={customSearch}
                    disabled={loading}
                    onChange={handleCustomSearchInput}
                    placeholder="Enter a location type"
                    onKeyDown={(e) => e.key === 'Enter'}
                />

                <Button variant="contained" color="primary" onClick={() => customSearchStart() } 
                    disabled={loading}>
                    Search Custom Location
                </Button>
                <hr/>
                <br/>
                <SavePointModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onSave={handleSavePoint}
                    pointName={pointName}
                    setPointName={setPointName}
                />
                <Typography variant="h6">Saved Locations</Typography>
                <Select
                    value=""
                    
                    disabled={loading}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="" disabled>
                        Select Location
                    </MenuItem>
                    {savedLocations.map((loc) => (
                        <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem> // Ensure loc has a Name property
                    ))}
                </Select>
                <br />
                <Button variant="contained" color="primary" onClick={() => setModalOpen(true)} 
                    disabled={loading}>
                    Save Location
                </Button>

                {wetlandsString && <h4>{wetlandsString}</h4>}

                {speciesString && <h4>{speciesString}</h4>}

                <Button variant="contained" color="secondary" onClick={captureMap}>
                    Capture Map
                </Button>

            </div>
            <div className="map-container" style={{ position: 'relative' }}>
            {loading && (
                    <div style={{ zIndex: 4000, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255, 255, 255, 0.4)' }}>
                        <CircularProgress />
                    </div>
                )}
                <MapContainer center={position} zoom={zoomLevel} style={{ height: '100%', width: '100%' }} key={Date.now()}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />

                    <MapEvents />
                    <Marker position={position} icon={pointerIcon} />


                    {Object.keys(locationData).map((location) => (
                        selectedCategories[location] && <GeoJSON data={locationData[location]} style={getStyle(location)} onEachFeature={onEachFeature}
                            pointToLayer={(feature, latlng) => {
                                // Check if the feature is a point
                                if (feature.geometry.type === 'Point' ) {
                                    if(customSearchTypes.indexOf(location) === -1){
                                        return L.marker(latlng, { icon: customIcon });
                                    }else{
                                        // TODO: I want to create an icon here that has the url that is stored in feature.properties.Icon
                                        const iconUrl = feature.properties.Icon || customSearchIcon.options.iconUrl;
                                        const gIcon = new L.Icon({
                                            iconUrl: iconUrl,
                                            iconSize: [40, 40],
                                            iconAnchor: [0, 0],
                                            popupAnchor: [20, 0]
                                        });
                                        return L.marker(latlng, { icon: gIcon });
                                    }
                                }
                                // Optionally handle other geometry types differently
                                return L.geoJSON(feature);
                            }}
                        />

                    ))}
                </MapContainer>
            </div>
        </div>
    );
}

export default MappingPage;