import React from 'react';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';



const data = [
    { name: 'Jan', target: 300000, actual: 240000 },
    { name: 'Feb', target: 280000, actual: 270000 },
    { name: 'Mar', target: 350000, actual: 290000 },
    { name: 'Apr', target: 320000, actual: 310000 },
    { name: 'May', target: 300000, actual: 330000 }
];

function TargetVsActualsChart() {
    return (

                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart
                        data={data}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1" gradientUnits="userSpaceOnUse">
                                <stop offset="0%" stopColor="#82ca9d" stopOpacity={0.8} />
                                <stop offset="100%" stopColor="#82ca9d" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Area type="monotone" dataKey="target" stroke="#8884d8" fill='#8884d8' strokeWidth={2} dot={{ stroke: '#8884d8', strokeWidth: 2 }} />
                        <Area type="monotone" dataKey="actual" stroke="#82ca9d" fill='#82ca9d' fillOpacity={.5} dot={{ stroke: '#8884d8', strokeWidth: 2 }} />
                        <Legend />
                    </AreaChart>
                </ResponsiveContainer>
    );
}

export default TargetVsActualsChart;