import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { PieChart, Pie, Cell } from 'recharts';

const data = [
    { name: 'Complete', value: 20, fill: '#00C49F' },
    { name: 'Pending', value: 80, fill: '#AAAAAA' },
];


function CircularProgressWithLabel(props
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
         <PieChart width={300} height={200}>
                    <Pie
                        data={data}
                        cx={120}
                        cy={110}
                        startAngle={0}
                        endAngle={360}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                    </Pie>
                </PieChart>
      <Box
        sx={{
          top: 20,
          left: -60,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid>
        <Typography
          variant="caption"
          component="div"
          marginLeft={'25px'}
          fontSize={'26px'}
          color="text.secondary"
        >{`${Math.round(props.value)}`}</Typography>
        
        <Typography
          variant="caption"
          component="div"
          fontSize={'16px'}
          color="text.secondary"
        >{'Days to go'}</Typography>
        </Grid>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel