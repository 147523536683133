import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Container, Typography, Button, CssBaseline, Paper } from '@mui/material';

const LoginPage = () => {
  const { oktaAuth } = useOktaAuth();

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  return (
    <div className="root">
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Paper className="paper">
          <Typography component="h1" variant="h5" className="title">
            Login Page
          </Typography>
          <Typography component="p" variant="body1" className="message">
            Login is managed through Okta. Click below to redirect. If you have not had your account set up for the beta test, please reach out to us and we can get you added to the list.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={login}
            className="button"
          >
            Login
          </Button>
        </Paper>
      </Container>
    </div>
  );
};

export default LoginPage;
