import React from 'react';
import { Grid, Card,CardContent,CardHeader, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import CircularProgressWithLabel from './circular-progress-with-label'
import { ResponsiveContainer } from 'recharts';

const deals = [
    { name: 'Alley Tract', progress: 90 },
    { name: 'Green Meadows', progress: 64 },
    { name: 'Wheatland tract', progress: 53 },
    { name: 'Bridgewater', progress: 47 },
    { name: 'Aspen Hills', progress: 27 }
  ];
  
  const selectedDealProgress = { name: 'Days to go', value: 20, fill: '#82ca9d' };

function OpenDeals() {
    return (
        
        <Card style={{height: '340px'}}>
            
        <CardHeader title="Open Deals">
        </CardHeader>
            <CardContent>

            <Grid container spacing={2}>
            <Grid item xs={5} sm={5}>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Deal</TableCell>
                                <TableCell align="right">Progress</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deals.map((deal) => (
                                <TableRow key={deal.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">{deal.name}</TableCell>
                                    <TableCell align="right">
                                        <Typography variant="body2">{deal.progress}%</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={5} sm={5}>
                <ResponsiveContainer width="100%" height={240}>
                   
                <CircularProgressWithLabel value={selectedDealProgress.value} />
                </ResponsiveContainer>
            </Grid>
        </Grid>

            </CardContent>
        </Card>
    );
}

export default OpenDeals;
