import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { getAuthState } from './AuthStateUtils';


const CustomSecureRoute = ({ children }) => {
  
    const { authState: oktaAuthState } = useOktaAuth();
    const [authState, setAuthState] = useState(oktaAuthState || getAuthState());
  const location = useLocation();


  useEffect(() => {
    if (oktaAuthState) {
      setAuthState(oktaAuthState);
    }
  }, [oktaAuthState]);
  
  if (!authState || !authState.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default CustomSecureRoute;


